export default {
  "header": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "productions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Productions"])},
    "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])},
    "association": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Association"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])}
  },
  "teaser": {
    "catchphrase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeah, we're producing stuff 😎"])},
    "numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some numbers"])},
    "membres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Members"])},
    "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])},
    "views": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Views"])},
    "subscribers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribers"])},
    "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Years"])},
    "read-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read more..."])}
  },
  "productions": {
    "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our productions"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
    "watch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watch"])},
    "en-sursis": {
      "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En Sursis"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks to the young officer Jules, Captain Patrick Corbier finally sees his chance to reach the elusive Vincent, whom he has been tracking alone for many years."])}
    },
    "un-chemin": {
      "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un Chemin"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["While humanity is struck down by a devastating pandemic, two friends try to survive."])}
    },
    "star-wars-une-quete-de-justice": {
      "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star Wars : Une quête de justice"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The galaxy is torn apart by the war between the Jedi and the Sith. In the midst of this turmoil, the notion of justice is uncertain, as each side appropriates it to serve its own interests. But there are those who do not hesitate to stand up to this endless confrontation..."])}
    },
    "un-ete-entre-amis": {
      "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un été entre amis"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Three friends are about to enjoy a rather ordinary vacation in the countryside. But adventures much more exciting than expected are waiting for them and their characters as well tempered as divergent will not arrange things..."])}
    }
  },
  "projects": {
    "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related projects"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
    "les-chroniques": {
      "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les Chroniques"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talkshow broadcasted live on Twitch."])}
    },
    "concert-polyband-2022": {
      "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PolyBand Concert 2022"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video and sound recording of the concert of March 17, 2022."])}
    }
  },
  "association": {
    "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The association"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Studio Rv & Co is a group of friends who are passionate about the world of cinema. Together, we produce from start to finish short films or amateur series on various themes that we like."])},
    "instagram-gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instagram gallery"])},
    "more-pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More pictures..."])}
  },
  "contact": {
    "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
    "social-networks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social networks"])}
  },
  "created-by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website created by"])}
}