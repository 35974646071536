export default {
  "header": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil"])},
    "productions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Productions"])},
    "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projets"])},
    "association": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Association"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])}
  },
  "teaser": {
    "catchphrase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouais, on produit des trucs 😎"])},
    "numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelques nombres"])},
    "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membres"])},
    "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projets"])},
    "views": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vues"])},
    "subscribers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnés"])},
    "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ans"])},
    "read-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus..."])}
  },
  "productions": {
    "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos productions"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau"])},
    "watch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regarder"])},
    "en-sursis": {
      "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En Sursis"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grâce au jeune lieutenant Jules, le capitaine Patrick Corbier aperçoit enfin sa chance d'atteindre l'insaisissable Vincent, qu'il traque en solitaire depuis de nombreuses années."])}
    },
    "un-chemin": {
      "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un Chemin"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tandis que l'humanité est terrassée par une pandémie dévastatrice, deux amis tentent de survivre."])}
    },
    "star-wars-une-quete-de-justice": {
      "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star Wars : Une quête de justice"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La galaxie est déchirée par la guerre entre les Jedi et les Sith. Dans ce contexte mouvementé, la notion de justice est incertaine, chaque camp se l’appropriant pour servir ses intérêts. Mais certains n'hésitent pas à se dresser face à cet affrontement sans fin..."])}
    },
    "un-ete-entre-amis": {
      "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un été entre amis"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trois amis s'apprêtent à profiter de vacances assez banales à la campagne. Mais des aventures bien plus palpitantes que prévu les attendent et leurs caractères aussi bien trempés que divergents ne vont pas arranger les choses..."])}
    }
  },
  "projects": {
    "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projets annexes"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir"])},
    "les-chroniques": {
      "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les Chroniques"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talkshow diffusé en direct sur Twitch."])}
    },
    "concert-polyband-2022": {
      "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concert PolyBand 2022"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Captation vidéo et sonore du concert du 17 mars 2022."])}
    }
  },
  "association": {
    "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'association"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le Studio Rv & Co est un regroupement d'amis passionnés par le monde du cinéma. Ensemble, nous produisons du début à la fin des courts-métrages ou des séries amateurs sur des thèmes variés qui nous plaisent."])},
    "instagram-gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galerie Instagram"])},
    "more-pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de photos..."])}
  },
  "contact": {
    "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous contacter"])},
    "social-networks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réseaux sociaux"])}
  },
  "created-by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site Web créé par"])}
}